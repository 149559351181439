import React from 'react';
import { signIn, signOut } from 'next-auth/react';
import { useEffect } from 'react';
import { Loader } from '../components/Loader';
import { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import { getServerSession } from 'next-auth';
import { authOptions } from './api/auth/[...nextauth]';
import { IDefaultPageProps } from '../interfaces';
import styled from '@emotion/styled';
import nookies from 'nookies';
import { ApolloErrorCookie } from '@constants/cookies';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ILoginProps extends IDefaultPageProps {}


const Login = () => {
  useEffect(() => {
    const initSignInRedirect = async () => {
      const cookies = nookies.get();

      if(cookies[ApolloErrorCookie] === 'JWTExpired') {
        nookies.destroy(null, ApolloErrorCookie);
        await signOut({callbackUrl: '/login'});
      }

      await signIn('keycloak', {callbackUrl: '/'});
    }

    initSignInRedirect();
  }, []);

  return (
    <LoaderWrapper>
      <Loader></Loader>
    </LoaderWrapper>
  );
};

const LoaderWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const getServerSideProps = async (
  props: GetServerSidePropsContext,
): Promise<GetServerSidePropsResult<ILoginProps>> => {
  const session = await getServerSession(props.req, props.res, authOptions);
  const query = props.query;
  const forceLogin = !!query.forceLogin;

  if(!forceLogin && session && session.expires && new Date(session.expires) > new Date()) {
    return {
      redirect: {
        destination: '/',
        permanent: false,
      }
    }
  }

  return {
    props: {
      session: null,
    }
  };
};

export default Login;
