import styled from '@emotion/styled';
import { Spin as AntdSpin } from 'antd';
import * as React from 'react';

export const Loader: React.FC = () => {
  return (
    <LoaderContainer>
      <AntdSpin size={'large'} style={{position: 'relative'}}></AntdSpin>
    </LoaderContainer>
  );
};

const LoaderContainer = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
